import { createContext, useContext, useEffect, ReactNode } from 'react';
import Plausible from 'plausible-tracker';

interface PlausibleContextType {
  trackEvent: (eventName: string, options?: Record<string, any>) => void;
}

const PlausibleContext = createContext<PlausibleContextType | undefined>(undefined);

export const PlausibleProvider = ({ children }: { children: ReactNode }) => {
  const plausible = Plausible({
    domain: 'auth.valolytics.gg',
    apiHost: "https://moon.valolytics.gg",
  });

  useEffect(() => {
    plausible.enableAutoPageviews();
    plausible.enableAutoOutboundTracking();
  }, []);

  const trackEvent = (eventName: string, options?: Record<string, any>) => {
    plausible.trackEvent(eventName, options);
  };

  return (
    <PlausibleContext.Provider value={{ trackEvent }}>
      {children}
    </PlausibleContext.Provider>
  );
};

export const usePlausible = () => {
  const context = useContext(PlausibleContext);
  if (!context) {
    throw new Error('usePlausible must be used within a PlausibleProvider');
  }
  return context;
};
